body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  .upload-list-inline .ant-upload-list-item {
    float: left;
    width: 200px;
    margin-right: 8px;
  }
  
  .upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
    float: right;
  }
  
  
  .table-header {
      display: flex;
      /* justify-content: flex-end; */
      margin-bottom: 20px;
  }
  
  .imagePreview {
    display: flex;
    position: relative;
    width: 200px;
    height: 200px;
    overflow:hidden;
    margin-right: 8px;
    margin-bottom: 8px;
  }
  .imagePreview .actionBar {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      transition: 0.3s;
  }
  
  .imagePreview .actionBar:hover {
      background-color: #0006;
  }
  
  .imagePreview .actionBar:hover span {
    display: flex;
  }
  .imagePreview .actionBar span {
      display: none;
      cursor: pointer;
      width: 25px;
      height: 25px;
      background-color: #2f2f2f;
      border-radius: 25px;
      align-items: center;
      justify-content: center;
  }
  
  
  .gallery-image-wrapper {
    height: calc(70vh - 100px);
    margin-top: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 10px;
    display:flex;
    flex-wrap: wrap;
  }
  .gallery-image-wrapper .photo-item {
    position:relative;
    border: solid transparent 4px;
    padding: 2px;
    width: 200px;
    height: 200px;
    overflow:hidden;
  }
  
  .gallery-image-wrapper .photo-item.active {
    border-color: #1890ff;
  }
  
  .gallery-image-wrapper .photo-item.active .checkMark {
    display: block !important;
  }
  
  .gallery-image-wrapper .photo-item img {
    width: 100%;
    cursor: pointer;
  }
  .gallery-image-wrapper .photo-item .checkMark {
    display: none;
    position: absolute;
    top: -5px;
    right: -5px;
  }
  .gallery-image-wrapper .photo-item .checkMark .checked {
    display: block;
  }
  .gallery-image-wrapper .photo-item .checkMark .remove {
    display: none;
  }
  
  .gallery-image-wrapper .photo-item .checkMark:hover .checked {
    display: none;
  }
  
  .gallery-image-wrapper .photo-item .checkMark:hover .remove {
    display: block;
  }
  .gallery-image-wrapper .photo-item .checkMark span {
    color: #1890ff;
    font-size: 24px;
    background: #fff;
    border: solid 1px;
  }
  
  .content {
    border-bottom: solid #CCC 1px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  
  
  .row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
  }
  
  .row-dragging td {
    padding: 16px;
  }
  
  
  .row-dragging .drag-visible {
    visibility: visible;
  }